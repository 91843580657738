import bundleLoader from '@entryscape/rdforms/src/template/bundleLoader.js';
import ItemStore from '@entryscape/rdforms/src/template/ItemStore.js';
import { namespaces } from '@entryscape/rdfjson';
import { i18n } from 'esi18n';
import {renderForms} from './form.js';
import {renderNamespaces} from './namespaces.js';
import {renderVocabularies} from './vocab.js';
import {renderAppendixHeader} from './domUtils.js';
import mainTemplate from './main.html';
import navTemplate from './nav.html';
import specNLS from "./nls/spec.nls";
import tocControlls from "./tocControlls.js";
import '../assets/style.less';

export default (config, nav, main) => {
  if (config.namespaces) {
    namespaces.add(config.namespaces);
  }
  if (config.language) {
    i18n.setLocale(config.language);
  }
  const itemStore = new ItemStore();
  let navNode = typeof nav === 'string' ? document.getElementById(nav) : nav;
  let mainNode = typeof main === 'string' ? document.getElementById(main) : main;
  if (!navNode) {
    navNode = document.getElementsByTagName('nav')[0];
    if (!navNode) {
      navNode = document.createElement('nav');
      document.body.appendChild(navNode);
    }
  }
  navNode.id = 'toc';
  if (!mainNode) {
    mainNode = document.getElementsByTagName('main')[0];
    if (!mainNode) {
      mainNode = document.createElement('main');
      document.body.appendChild(mainNode);
    }
  }
  let sectionNode;
  const sectionNodes = document.querySelectorAll('main section');
  if (sectionNodes.length > 0) {
    sectionNode = sectionNodes[0];
  }

  let bundle = i18n.getLocalization(specNLS);
  if (Object.keys(bundle).length === 0) {
    bundle = bundle.__proto__;
  }
  navNode.innerHTML = i18n.renderNLSTemplate(navTemplate, bundle);
  mainNode.innerHTML = i18n.renderNLSTemplate(mainTemplate, bundle);
  const render = () => {
    if (config.introduction) {
      document.getElementById('introText').innerHTML = config.introduction;
    } else if (sectionNode) {
      document.getElementById('introText').appendChild(sectionNode);
    }
    const aps = document.getElementById('aps');
    const tocAps = document.getElementById('toc-aps');
    const sup = document.getElementById('sup');
    const tocSup = document.getElementById('toc-sup');

    renderForms({
        items: config.main.map(id => itemStore.getItem(id)),
        node: aps,
        nodeToc: tocAps
      },
      {
        items: config.supportive.map(id => itemStore.getItem(id)),
        node: sup,
        nodeToc: tocSup,
      },
      config.extras);
    renderNamespaces(document.getElementById('nss'));
    renderVocabularies(document.getElementById('vocs'), document.getElementById('toc-vocabs'));
    const appendicies = document.querySelectorAll('[data-rdforms-appendix]');
    if (appendicies.length > 0) {
      document.getElementById("toc-appendicies-li").style.display = null;
      document.getElementById("appendicies-content").style.display = null;
      const ixes = document.getElementById('ixes');
      const tocIxes = document.getElementById('toc-appendicies');
      appendicies.forEach((apx) => {
        const name = apx.getAttribute('data-rdforms-appendix');
        renderAppendixHeader(name, ixes, tocIxes);
        ixes.appendChild(apx);
      });
    }
  };
  if (Array.isArray(config.bundles) && config.bundles.length > 0 && Array.isArray(config.bundles[0])) {
    bundleLoader(itemStore, config.bundles, render);
  } else if (config.bundles) {
    config.bundles.forEach(b => itemStore.registerBundle({source: b}));
    render();
  } else if (config.bundle) {
    itemStore.registerBundle({source: config.bundle});
    render();
  } else {
    console.log("No bundle configured.");
  }

  if (config.tocControlls !== false) {
    tocControlls();
  }
};